import request from "../request";

// 查询资源列表
export function relist(params) {
  return request({
    url: `/image/resource/list`,
    method: 'get',
    params,
  })
}


// 新增资源
export function addre(data) {
  return request({
    url: `/image/resource`,
    method: 'post',
    data,
  })
}

// 修改资源
export function putre(data) {
  return request({
    url: `/image/resource`,
    method: 'put',
    data,
  })
}

// 删除资源
export function delre(data) {
  return request({
    url: `/image/resource?ids=`+data.ids,
    method: 'delete',
    data,
  })
}


// 资源详情
export function reinfo(id) {
  return request({
    url: `/image/resource/`+id,
    method: 'get',
  })
}

export function reload(id) {
  return request({
    url: '/upFile/'+id,
    method: 'get',
    responseType: 'blob',
  });
}

