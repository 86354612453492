<template>
  <div style="background: #f4f4f4; padding-top: 20px; padding-bottom: 50px">
    <div class="title-all" v-if="type!='look'">资源上传</div>
     <div class="title-all" v-else>资源详情</div>
    <div class="listall">
      <div class="list">
        <div class="resource">
          <el-form label-position="top" :model="resourceFrom" ref="resourceFrom" class="demo-form-inline" :rules="rules">
            <el-form-item label="标题:" class="red" prop='title'>
              <el-input v-model="resourceFrom.title" placeholder="标题" :disabled="type == 'look'"></el-input>
            </el-form-item>

            <el-form-item label="简介:" class="blue" prop='description'>
              <el-input type="textarea" v-model="resourceFrom.description" placeholder="简介"
                        :disabled="type == 'look'"></el-input>
            </el-form-item>
            <el-form-item label="关键字:" class="green">
              <el-tag :key="tag" v-for="tag in dynamicTags" :closable="type != 'look'" :disable-transitions="false"
                      @close="handleClose(tag)">
                {{ tag }}
              </el-tag>
              <div v-if="type != 'look'" style="display: inline-block;">
                <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
                          @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 关键字</el-button>
              </div>

            </el-form-item>
            <el-form-item label="DOI:" class="orange">
              <el-input v-model="resourceFrom.doi" placeholder="DOI" disabled></el-input>
            </el-form-item>
            <div class="two">
              <el-form-item label="地点:">
                <el-input v-model="resourceFrom.location" placeholder="地点" :disabled="type == 'look'"></el-input>
              </el-form-item>

              <el-form-item label="GPS:">
                <el-input v-model="resourceFrom.gps" placeholder="GPS" :disabled="type == 'look'"></el-input>
              </el-form-item>

              <el-form-item label="扫描方式:">
                <el-input v-model="resourceFrom.scanningMode" placeholder="扫描方式" :disabled="type == 'look'"></el-input>
              </el-form-item>

              <el-form-item label="数据格式:">
                <el-input v-model="resourceFrom.dataFormat" placeholder="数据格式" :disabled="type == 'look'"></el-input>
              </el-form-item>
              <div class="author">
                <el-form-item v-for="(item, index) in resourceFrom.author" :label="'作者'" :key="item.key"
                              :prop="'author.' + index + '.value'" :rules="{
            required: true, message: '作者不能为空', trigger: 'blur'
          }">
                  <el-input v-model="item.value" placeholder="作者姓名" :disabled="type == 'look'"></el-input>
                </el-form-item>
              </div>
              <div class="unit">
                <el-form-item v-for="(item, index) in resourceFrom.unit" :label="'单位'" :key="item.key"
                              :prop="'unit.' + index + '.value'" :rules="{
            required: true, message: '单位不能为空', trigger: 'blur'
          }">
                  <el-input v-model="item.value" placeholder="单位名称" :disabled="type == 'look'"></el-input>
                  <div v-if="type != 'look'">
                    <el-button @click="addDomain" v-if="index == 0" class="btn" type="primary">新增</el-button>
                    <el-button @click.prevent="removeDomain(index)" class="btn" type="danger" v-else>删除</el-button>
                  </div>

                </el-form-item>
              </div>


            </div>


            <el-form-item label="上传文件:" class="green">
              <el-upload class="upload-demo" ref="upload" drag :action="$uploadUrl + '/upFile'"
                         :on-remove="handleRemove" :headers="token" :on-success="handleAvatarSuccess"
                         :on-change="imgchange" :auto-upload="false" :limit="3" :file-list="fileList"
                         v-if="type != 'look'" multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">*如文件过大（大于500M）请联系管理员上传</div>
              </el-upload>
              <div v-else class="filelist">
                <div class="single" v-for="item in fileList">{{ item.name }}</div>
              </div>
            </el-form-item>

            <el-form-item size="large">
              <div class="submit">
                <el-button type="primary" @click="submitForm('resourceFrom')" v-if="type != 'look'">确认上传</el-button>
                <el-button type="primary" @click="loadfile" v-else>下载资源</el-button>
              </div>
            </el-form-item>

          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { mapMutations } from 'vuex';
import { addre, putre, reinfo } from "@/api/resource"
export default {
  data() {
    return {
      resourceFrom: {
        title: "",
        description: "",
        keyword: "",
        location: "",
        gps: "",
        scanningMode: "",
        dataFormat: "",
        urlList: [],
        author: [{
          value: ''
        }],
        unit: [{
          value: ''
        }],
      },
      type: 'edit',
      fileList: [],
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      filenum: 0,
      filenumber: 0,
      token: {},
      rules: {
          title: [
            { required: true, message: '请输入标题', trigger: 'blur' },
          ],
          description: [
            { required: true, message: '请输入简介', trigger: 'blur' },
          ],
      }
    };
  },
  created() {
    this.token = {
      Authorization: getToken(),
    };
    if (this.$route.query.id) {
      this.type = this.$route.query.type
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapMutations(['SET_SEARCH_LIST']),
    async init() {
      if (this.$route.query.id) {
        const { data } = await reinfo(this.$route.query.id)
        let obj = { ...data }
        // obj.keyword = obj.keyword.split(',')
        this.dynamicTags = obj.keyword.split(',')
        obj.authorUnit = obj.authorUnit.split(',')
        obj.author = obj.authorUnit.map(v => {
          let arr = v.split('-')
          return {
            value: arr[0]
          }
        })
        obj.unit = obj.authorUnit.map(v => {
          let arr = v.split('-')
          return {
            value: arr[1]
          }
        })
        this.fileList = obj.upfileList.map(v => {
          return {
            name: v.upfileName,
            url: this.$uploadUrl + '/upFile/' + v.upfileId
          }
        })
        obj.urlList = []
        this.resourceFrom = Object.assign(this.resourceFrom, obj)
        // this.shopform = data
      } else {
        this.dynamicTags = []
        this.resourceFrom = {
          auditSuggest: '',
          auditId: '',
          title: "",
          description: "",
          keyword: "",
          location: "",
          gps: "",
          scanningMode: "",
          dataFormat: "",
          urlList: [],
          author: [{
            value: ''
          }],
          unit: [{
            value: ''
          }],
        }
      }

    },
    async getprops() {
      let option = Object.assign({}, this.resourceFrom)
      this.SET_SEARCH_LIST(option);
      return this.$router.push({
        path: '/home/allList',
        query: {
          type: 0,
        },
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arr = this.resourceFrom.author.map((v, index) => {
            return this.resourceFrom.author[index].value + "-" + this.resourceFrom.unit[index].value
          })
          this.resourceFrom.authorUnit = arr.join(',')
          console.log('data', this.resourceFrom);
          this.handleup()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async handleup() {
      if (this.filenum > 0) {
        this.$refs.upload.submit();
      } else {
        let obj = {
          urlList: this.resourceFrom.urlList,
          title: this.resourceFrom.title,
          description: this.resourceFrom.description,
          keyword: this.resourceFrom.keyword,
          authorUnit: this.resourceFrom.authorUnit,
          gps: this.resourceFrom.gps,
          location: this.resourceFrom.location,
          scanningMode: this.resourceFrom.scanningMode,
          dataFormat: this.resourceFrom.dataFormat,
        }
        let data = null
        if (this.id) {
          data = await putre(this.resourceFrom)
        } else {
          data = await addre(obj)
        }

        if (data.code === 200) {
          this.$message({
            type: 'success',
            message: data.msg
          });
          this.$refs.upload.clearFiles();
          this.filenumber = 0;
          this.filenum = 0;
          this.$emit('ok')
          // this.recheckflag = false;
        } else {
          this.$refs.upload.clearFiles();
          this.filenumber = 0;
          this.filenum = 0;
          // this.recheckflag = false;
          this.$message({
            type: 'error',
            message: redatas.msg
          });
        }
      }
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      let arr = this.dynamicTags.map(v => v)
      if (arr.length > 0) {
        this.resourceFrom.keyword = arr.join(',')
      }

    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
        let arr = this.dynamicTags.map(v => v)
        if (arr.length > 0) {
          this.resourceFrom.keyword = arr.join(',')
        }

      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    removeDomain(index) {
      // console.log(item);
      // var index1 = this.resourceFrom.author.indexOf(item)
      if (index == 0 || index > 0) {
        this.resourceFrom.author.splice(index, 1)
      }
      // var index = this.resourceFrom.unit.indexOf(item)
      if (index == 0 || index > 0) {
        this.resourceFrom.unit.splice(index, 1)
      }
    },
    addDomain() {
      this.resourceFrom.author.push({
        value: '',
        // key: Date.now()
      });
      this.resourceFrom.unit.push({
        value: '',
        // key: Date.now()
      });
    },
    // 附件上传
    handleRemove(file, fileList) {

      console.log('移除', fileList);

      this.resourceFrom.urlList = []
      this.resourceFrom.urlList = fileList.map(v => {
        return v.url
      })
      this.filenum = fileList.length;
    },
    imgchange(file, filelist) {
      console.log('文件', file, filelist);
      this.filenum = filelist.length;
      this.filenumber = 0
      filelist.forEach(v => {
        console.log(v, 'v');
        if (v.size) this.filenumber++
      })
    },
    async handleAvatarSuccess(res, file, fileList) {
      // this.imageUrl = URL.createObjectURL(file.raw);
      if (file.code == 500) {
        return this.$message({
          message: file.msg,
          type: "error",
        });
      }

      if(this.filenumber < fileList.length){
        this.filenumber++;
      }

      this.resourceFrom.urlList.push(res.data);
      if (this.filenumber === fileList.length) {
        let obj = {
          urlList: this.resourceFrom.urlList,
          title: this.resourceFrom.title,
          description: this.resourceFrom.description,
          keyword: this.resourceFrom.keyword,
          authorUnit: this.resourceFrom.authorUnit,
          location: this.resourceFrom.location,
          gps: this.resourceFrom.gps,
          scanningMode: this.resourceFrom.scanningMode,
          dataFormat: this.resourceFrom.dataFormat,
        }
        let data = null
        if (this.$route.query.id) {
          data = await putre(this.resourceFrom)
        } else {
          data = await addre(obj)
        }

        if (data.code === 200) {
          this.$message({
            type: 'success',
            message: data.msg
          });
          this.$refs.upload.clearFiles();
          this.filenumber = 0;
          this.filenum = 0;
          this.init();
        } else {
          this.$refs.upload.clearFiles();
          this.filenumber = 0;
          this.filenum = 0;
          this.$message({
            type: 'error',
            message: redatas.msg
          });
        }

      }
    },
    async loadfile() {
      if(!this.resourceFrom.upfileList || this.resourceFrom.upfileList.length<=0 ){
        this.$message({
            type: 'warning',
            message: '无可下载文件'
          });
      }
      this.resourceFrom.upfileList.map(async (v, index) => {
        setTimeout(() => {  // 修改了setTimeout传参方式 
          this.downloadFile(this.$uploadUrl + '/upFile/' + v.upfileId)
        }, 1000 * index)
      })
    },
    downloadFile(url) {
      let a = document.createElement('a') // 创建一个<a></a>标签
      a.href = url         //重点（如测试发现下载文件不存在/找不到，检查路径）
      // a.download = 'xxx.pdf'        
      a.style.display = 'none'           // 隐藏a标签
      document.body.appendChild(a)       // 将a标签追加到文档对象中
      a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove()
    },
  },
};
</script>

<style lang="scss" scoped>
.listall {
  width: 73%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
}

.title,
.title-all {
  width: 100%;
  margin: 0 auto;
  font-size: 20px;
  color: #333333;
  text-align: left;
  padding: 16px 26px;
  box-sizing: border-box;
  border-bottom: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;
  font-weight: 800;
}

.title-all {
  font-size: 30px;
  text-align: center;
  box-shadow: none;
  width: 73%;
  border: none;
}

.list {
  width: 100%;
  padding: 35px 84px 35px 42px;
  box-sizing: border-box;

  &-title {
    margin: 20px 0;
  }

  .resource {
    box-sizing: border-box;
    padding: 1%;

    caret-color: #333333;
  }

  .button {
    margin: 50px auto;
    width: 30%;
    margin-left: 35%;
  }
}

.two {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ::v-deep .el-form-item {
    width: 48%;
  }

  .author,
  .unit {
    position: relative;
    width: 48%;

    ::v-deep .el-form-item {
      width: 100%;
    }

    .btn {
      position: absolute;
      right: -80px;
      top: 0;
    }
  }
}

.demo-form-inline {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: flex-start;

  input {
    border-radius: 0px !important;
  }

  ::v-deep .el-form-item {
    // width: 50%;
  }

  ::v-deep .el-input {
    caret-color: black;
    // width: 270px;
  }

  ::v-deep .el-form-item__content {
    margin: 0 !important;
  }

  ::v-deep .el-form-item__label {
    position: relative;
    font-weight: 800;

  }

  ::v-deep .el-select {
    // width: 270px;
  }

  ::v-deep .el-form-item__label {
    width: 120px !important;
    padding: 0 5px 0 0 !important;
    font-size: 18px !important;
  }

  .el-form-item {
    // width: 50%;

    // ::v-deep .el-form-item__label {
    //   &::after {
    //     display: none;
    //   }
    // }
  }


}

::v-deep .el-form-item__content {
  // display: inline-block;
}

::v-deep .el-form-item {
  left: 2%;
  // display: flex;

  .el-form-item__content {
    width: 100%;
  }
}


.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.submit {
  display: flex;
  justify-content: end;
}

::v-deep .el-upload__tip {
  position: absolute;
  left: 390px;
  top: 140px;
  color: #FF5F5F;
}

.filelist {
  .single {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #D0D0D0;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    display: flex;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;
  }
}
</style>
